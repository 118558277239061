import Vue from 'vue'
import App from './App.vue'
import Main from './components/Main'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

Vue.config.productionTip = false

const routes = [
  { name: 'uk', path: '/', component: Main }, //укр
  { name: 'ru', path: '/ru', component: Main }, //рус
  { name: 'en', path: '/en', component: Main }, //анг
  { name: 'cn', path: '/cn', component: Main }, //кит
  { name: 'pl', path: '/pl', component: Main }, //пол
]

const router = new VueRouter({
  routes // сокращённая запись для `routes: routes`
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
