<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  data(){
    return {
      lang: 'uk',
    }
  },
  watch: {
    $route:{
      deep: true,
      handler(){
        this.lang = this.$route.name;
      }
    }
  }
}
</script>